import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import { YKEmailForm } from 'components/pay'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
// import donateSB from './img/donate_sb.svg'
// import donateYM from './img/donate_ym.svg'
// import donatePP from './img/donate_pp.svg'
import SEO from 'components/seo'

export default withMainLayout(DonatePage)

function DonatePage() {
  return(
    <div className='container'>
      <SEO
        title='Поддержать проект'
      />
      <div className={css.donatePage}>
        <h1
          align='center'
          style={{marginBottom: '50px'}}
        >
          Поддержать проект
        </h1>
        <h3
          align='center'
          style={{marginBottom: '15px'}}
        >
          Помогите внести свет Шабата в еще один дом!
        </h3>
        <p
          className={css.donateText}
        >
          Все полученные денежные средства пойдут на поддержку проекта Шабат Шалом, направленного на развитие и сохранение еврейской культуры и традиций
        </p>
        <div className={css.donateForm}>
          <YKEmailForm/>
        </div>
        <br />
        <br />
        {/*<h3
          align='center'
          style={{margin: '0 0 30px'}}
        >
          Выберите способ оплаты:
        </h3>*/}
        {/*<div className={css.donateButtons}>
          <a
            className={css.donateButton}
            href='/'
            target='_blank'
          >
            <img src={donateSB} alt='Sberbank'/>
          </a>
          <a
            className={css.donateButton}
            href='/'
            target='_blank'
          >
            <img src={donateYM} alt='YandexMoney'/>
          </a>
          <a
            className={css.donateButton}
            href='/'
            target='_blank'
          >
            <img src={donatePP} alt='PayPal'/>
          </a>
        </div>*/}
        <p className={cn('caption', css.donateSubText)}>
          Отправляя пожертвование, я подтверждаю, что ознакомлен с <Link to='/public-offer' className={cn('caption', css.donateSubTextLink)}>Публичной офертой</Link>, и даю согласие на <Link to='/privacy-policy' className={cn('caption', css.donateSubTextLink)}>Обработку персональных данных</Link>
        </p>
        <div className={css.donateQuote}>
          <h5 className={css.donateQuoteText}>
            «…не ожесточи сердца своего и не сожми руки твоей пред нищим братом твоим. Но открой ему руку свою и дай ему…» 
          </h5>
          <div className={css.donateQuoteTorah}>
            — (Тора, Дварим, 15 — 7; 8)
          </div>
        </div>
      </div>
    </div>
  );
}